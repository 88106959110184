

#identity-options-wrapper{
    padding: 0px 40px;
    font-size: 28px;

    position: fixed;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    background: #ffffff;

    z-index: 99;
}

#identity-options-wrapper p.title{
    font-size: 44px;
    margin-top: 130px
}

#identity-options-wrapper p.option{
    display: flex;
    align-items: center;
    margin:50px 0px
}

#identity-options-wrapper p.option .text{
    margin-left:20px;
}

#identity-options-wrapper .submit{
    margin-top:100px;
}
