.ChangeResult {
    margin-top: 30px;
    margin-bottom: 90px;
    width: 750px;
    overflow-x: hidden;
}

.ChangeResultTop {
    width: 750px;
}


.ChangeResult .sections .bg2,
.ChangeResult .sections .bg1 {
    padding: 90px 40px;
}

.ChangeResult .bg1 {
    background: #ffffff;
}

.ChangeResult .bg2 {
    background: #f2f2f2;
}

.ChangeResult .sections img, .ChangeResult .sections video {
    max-width: 100%;
}

.ChangeResult .sections video {
    width: 670px;
    height: 349px;
}
.ChangeResult .sections{
    margin-bottom: 60px;
}

.ChangeResult .h2 {
    text-align: left;
    font-size: 36px;
    color: #333333;
    margin-bottom: 30px;
    display: flex;
}

.ChangeResult .para {
    font-weight: normal;
    font-size: 24px;
    color: #4B4B4B;
    line-height: 36px;
    margin-bottom: 30px;
    display: flex;


}