
.MusicBtn{
    display:flex;
    position: absolute;
    right:20px;
    top:20px;
    text-align: right;
    justify-content: flex-end;
    z-index: 9;
}
.MusicBtn img{
    width:57px;
    height: 57px;
    margin-right: 10px;
    margin-top: 10px;
    background: #fff;
    border-radius: 50px;

    animation: App-logo-spin infinite 20s linear;
}
.MusicBtn.mute{
    opacity: 0.5;
}
.MusicBtn.mute img{
    animation: none;
}
@keyframes App-logo-spin {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
}
