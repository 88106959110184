
.QuickContact {
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 10;
}

.QuickContact .dialog {
    width:690px;

    background-color: #fff;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    position: fixed;
    bottom: 0px;
    left: 0px;
    right: 0px;
    padding: 0 30px;
    z-index: 99;
}
.QuickContact .dialog .dialog-title {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.QuickContact  .dialog .dialog-title .close {
    padding: 20px 20px;
    padding-right: 0px;
}
.QuickContact .qrRow{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width:100%;
}
.QuickContact .qrLi{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
}
.QuickContact .qrLi img{
    width:200px;
    height:200px;
}
.QuickContact .qrLi span{
    margin-bottom: 90px;
}