

#crm-form-wrapper {
    width:670px;
    background: #f2f2f2;
    padding: 0px 40px 30px;
    font-size: 28px;
    color: #5E5E5E;
}

#crm-form-wrapper p.title {
    text-align: center;
    font-weight: bold;
    color: #030303;
    font-size: 30px;
    padding: 40px 0px 30px;
}

#crm-form-wrapper .crm {
    background-color: #fff;
    padding: 0px 30px;
}

#crm-form-wrapper p.form-line {
    padding: 30px 0px;
    border-bottom: 1px solid #ccc;
    height: 101px;
    box-sizing: border-box;
    /*font-weight: bold;*/
}

#crm-form-wrapper p.form-arrow-line {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#crm-form-wrapper p.form-line:nth-last-child(1) {
    border-bottom: 0px;
}

#crm-form-wrapper p.form-line.fullfill {
    color: #333333;
}

#crm-form-wrapper p.form-line .form-input {
    height: 40px;
    line-height: 40px;
    font-size: 28px;
    color: #333333;
    width: 100%;
    border: none;
    /*font-weight: bold;*/

}

#crm-form-wrapper .form-input::-webkit-input-placeholder {
    color: #5e5e5e;
    /*font-weight: bold;*/
}

#crm-form-wrapper .note {
    margin-top: 20px;
    background-color: #fff;
    padding: 20px;
}

#crm-form-wrapper .note .note-input {
    box-sizing: border-box;
    border-style: none;
    border: 1px solid #e1e1e1;
    width: 100%;
    height: 227px;
    padding: 20px;
    font-size: 28px;
    line-height: 40px;
    color: #333333;
}

#crm-form-wrapper .submit {
    padding: 48px 0px;
    background-color: #fff;
}

#crm-form-wrapper .form-bottom-dialog {
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 11;

}

#crm-form-wrapper .form-bottom-dialog .dialog {
    width:690px;

    background-color: #fff;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    position: fixed;
    bottom: 0px;
    left: 0px;
    right: 0px;
    padding: 30px;
}

#crm-form-wrapper .form-bottom-dialog .dialog .dialog-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#crm-form-wrapper .form-bottom-dialog .dialog .dialog-title span {
    font-weight: bold;
    font-size: 32px;
    color: #000;
}

#crm-form-wrapper .form-bottom-dialog .dialog .dialog-title .close {
    padding: 20px 20px;
    padding-right: 0px;
}

#crm-form-wrapper .form-bottom-dialog .dialog .dialog-content {
    display: flex;
    flex-wrap: wrap;
}

#crm-form-wrapper .form-bottom-dialog .dialog .dialog-content span {
    box-sizing: border-box;
    color: #646464;
    font-size: 26px;
    border: 1px solid #979797;
    border-radius: 40px;
    margin: 20px 10px;
    padding: 10px 15px;
    min-width: 140px;
    text-align: center;
}

#crm-form-wrapper .form-bottom-dialog .dialog .dialog-content span.active {
    color: #3C56F8;
    border: 1px solid #3C56F8;
}

#crm-form-wrapper .form-bottom-dialog .dialog .dialog-footer {
    padding-top: 30px;
    padding-bottom: 20px;
}

#crm-form-wrapper .qrLi{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    padding:0 0 30px  0;
}
#crm-form-wrapper .qrLi img{
    width:200px;
    height:200px;
}
#crm-form-wrapper .qrLi span{
    /*margin-bottom: 90px;*/
}