html{
  background: #000;
  scroll-behavior: smooth;
}
body {
  background: #ffffff;
  max-width: 750px;
  width:750px;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


* {
  padding: 0px;
  margin: 0px;
}


input,
button,
select,
textarea {
  outline: none;
  -webkit-appearance: none;
}
* a{
  text-decoration: none;
  color : inherit;
}